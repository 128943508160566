
.section--has-background {
    background-color: #72c952;
    color: #fff;
    position: relative;
    min-height: 600px;
    overflow: hidden;
  }
  
  .section--has-background::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 299%;
    top: -75%;
    left: -3%;
    z-index: 2;
    
  }
  
  .footerWidget {
    position: relative;
    z-index: 99;
  }
  
  h3.widgetTitle.t-white {
    margin-bottom: 30px;
  }
  
  ul.footerNav {
    padding: 0;
  }
  
  ul.footerNav>li {
    list-style: none;
    border-bottom: 1px solid #ffffff52;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  
  ul.footerNav>li>svg {
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  
  ul.footerNav>li>svg>polyline {
    stroke: #fff;
  }
  
  ul.footerNav>li>a {
    color: #fff;
    font-size: 20px;
    padding: 10px 0;
    display: inline-block;
    margin-left: 5px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  
  ul.footerNav>li:hover>svg {
    margin-left: 3px;
  }
  
  .text-white a {
    color: #fff;
  }
  
  .contact-content {
    margin-bottom: 30px;
  }
  
  ul.socialmedia {
    padding: 0;
    list-style: none;
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  ul.socialmedia>li>a>svg>path,
  .address svg path,
  .phone svg path {
    fill: #fff;
  }
  
  ul.socialmedia>li>a:hover>svg>path {
    fill: #00000080;
  }
  
  ul.socialmedia li a {
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  
  ul.socialmedia li img {
    max-width: 30px;
    max-height: 30px;
  }
  
  ul.socialmedia li a:hover {
    background: #fff;
  }
  
  .address,
  .phone,
  .email {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  
  .address svg,
  .phone svg {
    font-size: 30px;
  }
  
  span.phone-wrap {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
  
  .phone-wrap a,
  .eamil a {
    color: #fff;
  }
  
  .phone-wrap a:hover,
  .eamil a:hover {
    color: #00000080;
  }
  
  .copywrwp {
    padding: 50px 15px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  @media screen and (max-width:768px) {
    .bannerWrapitem {
      padding: 60px 30px !important;
    }
  
    .first_item .wrapper-sec {
      min-height: 297px;
      padding: 30px;
    }
  
    .scond_item .wrapper-sec {
      padding: 60px 30px;
    }
  
    .cropsinner {
      margin-bottom: 30px;
    }
  
    .form-sec {
      margin-top: 30px !important;
    }
  
    .form-sec .phone,
    .form-sec .email {
      align-items: start;
    }
  
    .name,
    .name div {
      display: block !important;
    }
  
    .name div,
    .innerContainer input {
      width: 100% !important;
    }
  
    .copywrwp span {
      display: none;
    }
  
    .footerWidget {
      margin-bottom: 30px;
    }
  
    .footerWidget h3,
    .contact-content {
      text-align: center;
    }
  
    ul.socialmedia,
    span.phone-wrap {
      justify-content: center;
    }
  
    .address,
    .phone,
    .email {
      align-items: center;
    }
  
    .image-gallery {
      margin-bottom: 30px;
    }
  }
  
  .copywrwp p {
    margin: 0;
    text-transform: capitalize;
  }
  
  .copywrwp a {
    color: #000;
  }
  a.text-red {
    color: red;
  }  