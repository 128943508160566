body {
  font-family: "Open Sans", sans-serif !important;
}

.sectionPadding,
.section-padding {
  padding: 100px 0;
}

h2.title-sec {
  text-align: center;
  margin-bottom: 40px;
  text-transform: capitalize;
  font-weight: 600;
}
.card {
  background: #fff;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
  padding: 1.5rem;
  border-radius: 20px !important;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  border: none !important;
}
.card img {
  max-height: 200px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-body {
  height: 50px;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.card h3,
.viewbtn {
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0;
  font-weight: 600;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  text-transform: capitalize;
}
span.viewbtn {
  position: relative;
  top: 50px;
  display: inline-block;
  width: 100%;
  color: #8bc34a;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  left: 0;
  border: 1px solid;
  max-width: 150px;
  padding: 6px;
  border-radius: 50px;
  font-weight: 400;
  z-index: 9999;
}
.card span.viewbtn:hover {
  background: #8bc34a;
  color: #fff;
}
.card:hover span.viewbtn {
  top: -55px;
}
.card:hover .producttitle {
  bottom: -59px;
  visibility: hidden;
}
.box_shadow1 {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

nav.navbar {
  border-bottom: 1.3rem solid #b6c275;
  position: relative;
  z-index: 9999;
  background: #fff;
}

li.nav-item {
  padding: 10px 20px;
}

li.nav-item a {
  color: #000;
}

li.nav-item:hover > a {
  text-decoration: underline !important;
}

a.nextpage-link {
  padding: 9px !important;
  background: #000;
  border-radius: 50%;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.logo {
  width: 100%;
  max-width: 200px;
}

img.pagebanner {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.header-slider {
  position: relative;
}
.overlayer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000075;
  top: 0;
  left: 0;
}
.bannertext {
  position: absolute;
  top: 45%;
  left: 10%;
}
.bannertext h1 {
  color: #fff;
}
.bodycontent h2 {
  margin-bottom: 30px;
}
.contwrap,
.content-wrap {
  font-size: 18px;
  line-height: 28px;
}
.contInfo {
  display: flex;
  flex-direction: column;
}

.border-gradient {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 3px;
}

.border-gradient-green {
  border-image-source: linear-gradient(to left, #00c853, #b2ff59);
}

.only-bottom {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
.procont p {
  color: #77002d;
  font-weight: 700;
}
.contInfo h3 {
  font-size: 20px;
  color: #77006a;
}
img.mdpropic {
  max-width: 100%;
  height: auto;
  border: 1px solid #0000000d;
}
.contInfo a {
  color: #000;
  transition: all 0.2s;
  margin-bottom: 10px;
}

.contInfo a:hover {
  color: #72c952;
}

.sectionPadding-40 {
  padding: 40px 0;
}
.biopart {
  box-shadow: 0 0 8px #0003;
  padding: 20px;
  border-radius: 5px;
}

.employ-sec {
  padding: 0 30px 30px;
  color: #495771;
  text-align: center;
}

.employWrap {
  position: relative;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
}

.epmmwinwrap {
  background: #f9f9f9;
  transition: 0.5s all ease-in-out;
  overflow: hidden;
  position: relative;
}

.employWrap:hover {
  background: #fff;
}

.proimg {
  overflow: hidden;
  transition: 0.5s all ease-in-out;
  text-align: center;
  padding: 20px;
}

.employWrap img {
  width: 100%;
  transition: 0.5s all ease-in-out;
  overflow: hidden;
  background: #fff;
  height: 250px;
  border-radius: 50%;
}

.employWrap:hover img {
  transform: scale(1.1);
}

h2.empname {
  color: #000;
  padding: 15px 5px;
  text-align: center;
  font-size: 20px;
}

.contactinfo {
  background: #fbfbfb;
  padding: 30px;
  border-radius: 5px;
}

.bodycontent h2 {
  margin-bottom: 30px;
}

.buttonsec {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  position: relative;
  z-index: 9;
}

.buttonsec .btnlink {
  width: 80px;
  text-align: center;
  background: #325f22;
  padding: 6px;
  color: #ededed;
  text-transform: uppercase;
  border-radius: 3px;
}

.buttonsec .btnlink:hover {
  color: #e8f0fe;
  background: #333;
}

.biosec {
  text-transform: capitalize;
}

.biosec p {
  margin-bottom: 5px;
}

a.mainlink {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.titlesecSingle {
  padding: 30px;
  background: #b6c275;
  color: #fff;
}

.contactinfo h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.singleancor {
  color: #000;
  font-size: 20px;
  transition: all 0.2s;
}

.singleancor svg {
  margin-right: 10px;
}

.employTitle p {
  text-transform: capitalize;
}
.block {
  display: flex;
  flex-direction: column;
}

.name {
  display: flex;
  gap: 10px;
}

.innerContainer {
  font-size: 1.3rem;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 54px 55px rgb(78 78 78 / 25%), 0 -12px 30px rgb(78 78 78 / 25%),
    0 4px 6px rgb(78 78 78 / 25%), 0 12px 13px rgb(78 78 78 / 25%),
    0 -3px 5px rgb(78 78 78 / 25%);
}

.innerContainer input {
  font-size: 1.2rem;
  margin: 10px 0 10px 0px;
  border-color: rgb(31, 28, 28);
  padding: 10px;
  border-radius: 5px;
  background-color: #e8f0fe;
}

.innerContainer textarea {
  margin: 10px 0 10px 0px;
  padding: 5px;
  border-color: rgb(31, 28, 28);
  border-radius: 5px;
  background-color: #e8f0fe;
  font-size: 20px;
}

.innerContainer h1 {
  text-align: center;
  font-weight: 600;
}

.name div {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.block button {
  padding: 10px;
  font-size: 20px;
  width: 30%;
  border: 3px solid black;
  border-radius: 5px;
}

.button {
  display: flex;
  align-items: center;
}

textarea {
  resize: none;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

a {
  text-decoration: none !important;
}

img {
  max-width: 100%;
}

.t-antiquewhite {
  color: antiquewhite;
}

.t-dark {
  color: #000 !important;
}

.t-white {
  color: #fff;
}

.btitle {
  font-family: "Exo", sans-serif;
}

.sectionPadding {
  padding: 100px 0;
}

.bg-gray {
  background-color: #464547;
}

.bg-white {
  background-color: #fff;
}

.bg-dark {
  background-color: #191919;
}

.bg-cian {
  background-color: #771131;
  color: #fff;
}

.bg-green {
  background-color: #b6c275;
}

.bg-whitesmoke {
  background-color: #f5f5f5;
}

.bgFeather {
  background-color: #f5f5f5;
}

.bg-red,
.king-feeds,
.king-feeds-ltd {
  background-color: #ec3b45;
}

.bg-red:hover,
.king-feeds:hover,
.king-feeds-ltd:hover {
  background-color: #8bc34a;
}

.bg-redsmoke {
  background-color: #f5000e29 !important;
}

.bg-darkGreen,
.gorgica-bangladesh-ltd,
.georgica-bd-ltd {
  background-color: #23562e;
}

.bg-darkGreen:hover,
.gorgica-bangladesh-ltd:hover,
.georgica-bd-ltd:hover {
  background-color: #72c952;
}

.agril-foods-ltd:hover {
  background-color: #8bc34a;
}

.agril-foods-ltd {
  background-color: #df9844;
}

.scond_item {
  min-height: 450px;
}

/* Font Size*/
.fs-40 {
  font-size: 40px;
}

.banner-border-red {
  border-color: #ec3b45 !important;
}

.bannerWrapitem {
  padding: 60px !important;
  margin-top: 1.5%;
}

/*Width*/
.w-60 {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
}

/* Nave */
nav.navbar {
  border-bottom: 1.3rem solid #b6c275;
  position: relative;
  z-index: 99;
  background: #fff;
}

li.nav-item {
  padding: 10px 20px;
}

li.nav-item a {
  color: #000;
}

li.nav-item:hover > a {
  text-decoration: underline !important;
}

a.nextpage-link {
  padding: 9px !important;
  background: #000;
  border-radius: 50%;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.first_item .wrapper-sec:hover a.nextpage-link {
  background: #fff;
}

.first_item .wrapper-sec:hover a.nextpage-link svg.nextpage-icon path {
  stroke: #771130;
}

/* Sisterconsern*/
div#agril-foods-ltd .bg-color {
  background: #fbfbfb !important;
}

div#king-feeds-ltd .bg-color {
  background: #ec3b4444 !important;
}

div#king-feeds-ltd nav.navbar {
  border-bottom: 10px solid #ec3b45 !important;
}

div#king-feeds-ltd .bg-sec-color {
  background: #ec3b45 !important;
  color: #fff;
}

.kingsLogo {
  padding: 10px;
}

div#gorgica-bd-ltd .bg-color {
  background: #23562e1a !important;
}

div#gorgica-bd-ltd nav.navbar {
  border-bottom: 10px solid #23562e !important;
}

div#gorgica-bd-ltd .bg-sec-color {
  background: #23562e !important;
  color: #fff;
}

.wrapper-sec {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.branditem .wrapper-sec {
  height: 100%;
  justify-content: start !important;
}

.first_item .wrapper-sec {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  flex-direction: column;
  border-bottom: 5px solid transparent;
  position: relative;
  box-shadow: -1px -2px 20px #9b9b9b59;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.content.t-dark div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}

.wrapper-sec.bg-white.scwrwp {
  justify-content: center;
  align-items: center;
}

.scwrwp img {
  width: 100%;
  height: 180px;
  object-fit: contain;
}

span.sisName {
  color: #333;
  display: block;
  max-width: 80%;
  margin-top: 20px;
}

.branditem .wrapper-sec img {
  min-height: 160px;
}

.wrapper-sec img {
  max-width: 200px;
  height: auto;
  object-fit: contain;
}

.sisFirstTitle {
  font-size: 2rem;
  font-weight: 700;
  line-height: 4.4rem;
  margin: 0;
  text-align: center;
  padding: 1rem;
}

.first svg path {
  stroke: #b6c275;
}

a.hilited-link span {
  width: 200px;
  color: #41464b !important;
  font-size: 18px;
  font-weight: 600;
}

a.hilited-link {
  display: flex;
  gap: 10px;
  justify-content: start;
  margin-bottom: 10px;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  left: 0;
}

a.hilited-link svg {
  fill: #333;
  font-size: 30px;
}

a.hilited-link:hover {
  background: rgb(182 194 117 / 24%);

  left: 10px;
}

.scond_item .wrapper-sec {
  padding: 60px;
  display: flex;
  justify-content: start;
  min-height: 450px;
  flex-direction: column;
  border-bottom: 5px solid transparent;
  position: relative;
  box-shadow: -1px -2px 20px #9b9b9b59;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.third_item .wrapper-sec {
  padding: 60px 60px 20px;
  display: flex;
  justify-content: start;
  min-height: 450px;
  flex-direction: column;
  border-bottom: 5px solid transparent;
  box-shadow: 2px -2px 20px #9b9b9b59;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.wrapper-sec:hover {
  border-color: #66022c;
}

a.sAncor {
  background: #6b6a6c;
  display: flex;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  bottom: 60px;
  left: 60px;
}

svg.nextpage-icon {
  font-size: 30px;
}

a.sister-link {
  display: flex;
  justify-content: start;
  align-items: center;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  gap: 10px;
}

a.sister-link:hover svg {
  background-color: #b6c275 !important;
}

/* Product */
.card.box_shadow1:hover img {
  transform: scale(1.1);
}

.card img {
  max-height: 200px;
  object-fit: contain;
  width: 100%;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.card {
  border: none;
  border-radius: 25px;
  background: #fff;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
  padding: 1.5rem;
  border-radius: 20px !important;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  border: none !important;
}

.box_shadow1 {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.position-relative {
  position: relative !important;
}

.card {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.card-body {
  height: 50px;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.card:hover .producttitle {
  bottom: -59px;
  visibility: hidden;
}

.card h3,
.viewbtn {
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0;
  font-weight: 600;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  text-transform: capitalize;
}

.card:hover span.viewbtn {
  top: -55px;
}

.card span.viewbtn:hover {
  top: -65px;
  background: #8bc34a;
  color: #fff;
}

span.viewbtn {
  position: relative;
  top: 50px;
  display: inline-block;
  width: 100%;
  color: #8bc34a;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  left: 0;
  border: 1px solid;
  max-width: 150px;
  padding: 6px;
  border-radius: 50px;
  font-weight: 400;
  z-index: 9999;
}

/* Counter*/

img.hilightericon {
  background: #fff;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 10px;
}

/* footer*/
.section--has-background {
  background-color: #72c952;
  color: #fff;
  position: relative;
  min-height: 600px;
  overflow: hidden;
}

.section--has-background::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 299%;
  top: -75%;
  left: -3%;
  z-index: 2;
}

.footerWidget {
  position: relative;
  z-index: 99;
}

h3.widgetTitle.t-white {
  margin-bottom: 30px;
}

ul.footerNav {
  padding: 0;
}

ul.footerNav > li {
  list-style: none;
  border-bottom: 1px solid #ffffff52;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

ul.footerNav > li > svg {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

ul.footerNav > li > svg > polyline {
  stroke: #fff;
}

ul.footerNav > li > a {
  color: #fff;
  font-size: 20px;
  padding: 10px 0;
  display: inline-block;
  margin-left: 5px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

ul.footerNav > li:hover > svg {
  margin-left: 3px;
}

.text-white a {
  color: #fff;
}

.contact-content {
  margin-bottom: 30px;
}

ul.socialmedia {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  justify-content: center;
}

ul.socialmedia > li > a > svg > path,
.address svg path,
.phone svg path {
  fill: #fff;
}

ul.socialmedia > li > a:hover > svg > path {
  fill: #00000080;
}

ul.socialmedia li a {
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

ul.socialmedia li img {
  max-width: 30px;
  max-height: 30px;
}

ul.socialmedia li a:hover {
  background: #fff;
}

.address,
.phone,
.email {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.address svg,
.phone svg {
  font-size: 30px;
}

span.phone-wrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.phone-wrap a,
.eamil a {
  color: #fff;
}

.phone-wrap a:hover,
.eamil a:hover {
  color: #00000080;
}

.copywrwp {
  padding: 50px 15px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .bannerWrapitem {
    padding: 60px 30px !important;
  }

  .first_item .wrapper-sec {
    min-height: 297px;
    padding: 30px;
  }

  .scond_item .wrapper-sec {
    padding: 60px 30px;
  }

  .cropsinner {
    margin-bottom: 30px;
  }

  .form-sec {
    margin-top: 30px !important;
  }

  .form-sec .phone,
  .form-sec .email {
    align-items: start;
  }

  .name,
  .name div {
    display: block !important;
  }

  .name div,
  .innerContainer input {
    width: 100% !important;
  }

  .copywrwp span {
    display: none;
  }

  .footerWidget {
    margin-bottom: 30px;
  }

  .footerWidget h3,
  .contact-content {
    text-align: center;
  }

  ul.socialmedia,
  span.phone-wrap {
    justify-content: center;
  }

  .address,
  .phone,
  .email {
    align-items: center;
  }

  .image-gallery {
    margin-bottom: 30px;
  }
}

.copywrwp p {
  margin: 0;
  text-transform: capitalize;
}

.copywrwp a {
  color: #000;
}

/* single Product*/
h1.productTitle {
  font-size: 20px;
  color: #6d8f05;
}

p.cprice {
  font-size: 20px;
}

.regularPrice svg,
.cprice svg,
.offerpricesec svg {
  position: relative;
  top: -3px;
}

.price_tabil p {
  margin-bottom: 0;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
}

.price_tabil {
  display: flex;
  gap: 10px;
  background: #9cad44;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

p.regularPrice {
  text-decoration: line-through;
}

.bg-ctbt {
  background: #ffffff;
}

h2.productType {
  color: #66022c;
  font-weight: 700;
  line-height: 30px;
}

h3.packageInstractionTitle {
  font-size: 18px;
}

.packageInstraction ul {
  list-style: none;
  padding-left: 10px;
  line-height: 1.8rem;
}

p.resistance {
  font-weight: 600;
}

.tabilTitle {
  font-weight: 600;
  margin-bottom: 10px;
}

.vtitle {
  font-weight: 600;
}

.op-0 {
  opacity: 0;
}

.cropsinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

span.number,
span.plusIcon {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

span.itemname {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}

.cropsinner svg {
  background: #fff;
  width: 80px;
  height: 80px;
  padding: 20px;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  margin-bottom: 10px;
}

.factAndFigurs > .container {
  z-index: 9;
  position: relative;
}

/* carosul */
.mycar > div {
  display: flex;
  align-items: center;
}

.itemwrwpper img {
  width: 100%;
  height: 300px !important;
  object-fit: cover !important;
}

.itemwrwpper {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.itemwrwpper img {
  width: 100%;
  height: 100%;
}

h2.postTitle {
  position: absolute;
  z-index: 99;
  bottom: 0px;
  color: #fff;
  padding: 16px;
  font-size: 20px;
  width: 100%;
  text-transform: uppercase;
  padding-left: 40px;
  margin-bottom: 0;
}

.itemwrwpper:hover h2.postTitle {
  background-color: #a4b354a6;
  box-shadow: 2px 0px 15px #000;
}

.mycar > div > button:hover {
  background-color: #141619;
}

.styles-module_item-tracker__3bypy {
  gap: 10px;
}

.carosulWrapper button[data-direction="left"] {
  transform: rotate(180deg);
}

.carosulWrapper [data-direction="right"] {
  left: 5px;
}

/* contact page*/
.block {
  display: flex;
  flex-direction: column;
}

.name {
  display: flex;
  gap: 10px;
}

.innerContainer {
  font-size: 1.3rem;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 54px 55px rgb(78 78 78 / 25%), 0 -12px 30px rgb(78 78 78 / 25%),
    0 4px 6px rgb(78 78 78 / 25%), 0 12px 13px rgb(78 78 78 / 25%),
    0 -3px 5px rgb(78 78 78 / 25%);
}

.innerContainer input {
  font-size: 1.2rem;
  margin: 10px 0 10px 0px;
  border-color: rgb(31, 28, 28);
  padding: 10px;
  border-radius: 5px;
  background-color: #e8f0fe;
}

.innerContainer textarea {
  margin: 10px 0 10px 0px;
  padding: 5px;
  border-color: rgb(31, 28, 28);
  border-radius: 5px;
  background-color: #e8f0fe;
  font-size: 20px;
}

.innerContainer h1 {
  text-align: center;
  font-weight: 600;
}

.name div {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.block button {
  padding: 10px;
  font-size: 20px;
  width: 30%;
  border: 3px solid black;
  border-radius: 5px;
}

.button {
  display: flex;
  align-items: center;
}

textarea {
  resize: none;
}

/* About Us*/

.header-slider {
  position: relative;
}

.bannertext {
  position: absolute;
  top: 45%;
  left: 10%;
}

.bannertext h1 {
  color: #fff;
}

/* Sister consern header*/

.homelogo {
  max-height: 50px;
  width: 100%;
  object-fit: contain;
}

.agrilBack {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
}

span.homebtn svg polyline {
  stroke: #e49843;
}

span.homebtn svg {
  width: 60px;
  height: 60px;
  padding: 20px;
  background: #0000002e;
}

.agrilBack:hover span.homebtn svg {
  background-color: #7b0f2d;
}

.agrilBack span.homebtn {
  color: #111;
}

.sisterLogo {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.sisterLogoking {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.contactInfo a {
  color: #000 !important;
}

.inwrwp a {
  display: table-cell;
  vertical-align: middle;
  color: #41464b;
}

.inwrwp h3 {
  color: #41464b;
  font-weight: 700;
  font-size: 19px;
}

.inwrwp {
  display: table;
  width: 100%;
  border-radius: 5px;
  height: 300px;
  margin: 10px;
  background: #72c95269;
  text-align: center;
  box-shadow: 3px 3px 7px #00000069;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.inner-item:first-child .inwrwp {
  background: #aee699;
}

.inwrwp svg {
  font-size: 70px;
  margin-bottom: 20px;
}

.inwrwp:hover {
  margin-top: -10px;
  background-color: #72c952;
}

.inwrwp:hover a,
.inwrwp:hover h3 {
  color: #fff;
}

.text-overflow-clamp {
  width: 100%;
  /* this code clamps based on specified lines */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 11;
  display: -webkit-box;
  font-family: math;
}

img.navlogo {
  object-fit: contain;
}

/* Category*/
h3.cattitle {
  margin-bottom: 20px;
  font-family: math;
  font-weight: 700;
}

a.catlink:hover {
  background: #f7f7f7;
  border-radius: 15px;
}

.catlink {
  margin-bottom: 10px;
}

a.catlink:hover span.catname {
  text-indent: 10px;
}

span.catname {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 10px;
  display: inline-block;
  transition: all 0.2s;
}

img.pagebanner {
  width: 100%;
  object-fit: cover;
}

.overlayer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000075;
  top: 0;
  left: 0;
}

.contwrap,
.content-wrap {
  font-size: 18px;
  line-height: 28px;
}

.contwrap h1,
.contwrap h2,
.contwrap h3,
.contwrap p {
  margin-bottom: 15px;
}

.employ-sec {
  padding: 0 30px 30px;
  color: #495771;
  text-align: center;
}

.employWrap {
  position: relative;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
}

.epmmwinwrap {
  background: #f9f9f9;
  transition: 0.5s all ease-in-out;
  overflow: hidden;
  position: relative;
}

.employWrap:hover {
  background: #fff;
}

h2.empname {
  color: #000;
  padding: 15px 5px;
  text-align: center;
  font-size: 20px;
}

.contactinfo {
  background: #fbfbfb;
  padding: 30px;
  border-radius: 5px;
}

.bodycontent h2 {
  margin-bottom: 30px;
}

.buttonsec {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  position: relative;
  z-index: 9;
}

.buttonsec .btnlink {
  width: 80px;
  text-align: center;
  background: #325f22;
  padding: 6px;
  color: #ededed;
  text-transform: uppercase;
  border-radius: 3px;
}

.buttonsec .btnlink:hover {
  color: #e8f0fe;
  background: #333;
}

.biosec {
  text-transform: capitalize;
}

.biosec p {
  margin-bottom: 5px;
}

a.mainlink {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.titlesecSingle {
  padding: 30px;
  background: #b6c275;
  color: #fff;
}

.contactinfo h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.singleancor {
  color: #000;
  font-size: 20px;
  transition: all 0.2s;
}

.singleancor svg {
  margin-right: 10px;
}

.singleancor:hover {
  color: #72c952;
}

.employTitle p {
  text-transform: capitalize;
}

.right-sec {
  padding: 80px 30px;
}

.wrapper-sec .kingsLogo {
  max-width: 200px;
  height: auto;
  max-height: 140px;
  object-fit: contain;
  margin: auto;
  display: block;
}

.first_item:hover .wrapper-sec {
  background: #ededed !important;
}

a.text-red {
  color: red;
}

img.image-gallery-image {
  max-width: 100%;
  height: 500px !important;
  object-fit: contain !important;
}

.fullscreen img.image-gallery-image {
  max-width: 100%;
  width: auto !important;
  height: auto !important;
  object-fit: contain !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 50px !important;
  width: 50px !important;
}

.paginationwrwp {
  display: flex;
  gap: 10px;
  justify-content: center;
}

span.current-page.btn.btn-outline-secondary {
  background: #72c952;
  color: #fff;
}

.pagenumber {
  display: flex;
  gap: 5px;
}

.image-gallery-fullscreen-button {
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullscreen .image-gallery-slides {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen .image-gallery-swipe {
  height: 90vh;
}

.fullscreen .image-gallery-center {
  display: block !important;
}

.image-gallery-fullscreen-button {
  opacity: 0;
}

.image-gallery-slide-wrapper:hover .image-gallery-fullscreen-button {
  opacity: 1;
}

.thumbnails-swipe-horizontal {
  border: 1px solid #e9e9e9;
  border-top: 0;
}

.image-gallery-slides {
  border: 1px solid #ededed;
}

.fullscreen .thumbnails-swipe-horizontal,
.fullscreen .image-gallery-slides {
  border: none;
}

.productType {
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 20px;
  font-size: 30px;
  margin-top: -5px;
}

.card-emp {
  padding: 15px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.7);
  user-select: none;
  position: relative;
}

.card-emp .cover-photo {
  position: relative;
  background: #72c952;
  background-size: cover;
  height: 80px;
  border-radius: 5px 5px 0 0;
  display: flex;
}

.card-emp .profile {
  position: relative;
  width: 120px;
  left: 15px;
  border-radius: 50%;
  background: #fff;
  padding: 5px;
}

.card-emp .profile-name {
  font-size: 20px;
  color: #fff;
  position: relative;
  top: 28px;
  left: 24px;
}

.card-emp .about {
  margin-top: 30px;
  line-height: 1.6;
  position: relative;
  left: 145px;
}

.card-emp .about p {
  margin-bottom: 10px;
}

p.department {
  font-weight: 500;
}

p.designeation {
  font-weight: 700;
}

.card-emp .btn {
  margin: 30px 15px;
  background: #212529;
  padding: 10px 25px;
  border-radius: 3px;
  border: 1px solid #212529;
  font-weight: bold;
  font-family: Montserrat;
  cursor: pointer;
  color: #fff;
  transition: 0.2s;
}

.card-emp .btn:last-of-type {
  background: transparent;
  border-color: #72c952;
  color: #72c952;
}

.card-emp .btn:hover {
  background: #72c952;
  color: #fff;
}

.card-emp .icons {
  width: 180px;
  margin: 0 auto 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.card-emp .icons i {
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  transition: 0.2s;
}

.card-emp .icons i:hover {
  color: #7ce3ff;
}

img.propic {
  border-radius: 50%;
  object-fit: contain;
}

h2.title-md {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  color: #d14f42;
}

.desig-md {
  color: #a8a9ab;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  font-style: italic;
  text-transform: none;
  text-decoration: none;
}

.bio-md {
  color: #9d9d9d;
  font-size: 15px;
  line-height: 24px;
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
}

span.iconsec svg {
  font-size: 20px;
}

span.iconsec {
  display: flex;
  gap: 10px;
}

.titlesec {
  justify-content: space-between;
  display: flex;
}

span.iconsec a {
  color: #8c8d95;
}

span.iconsec a:hover {
  color: #d14f42;
}

.dropdown-menu {
  width: 220px;
  padding: 0 !important;
}
li.nav-link {
  border-bottom: 1px solid #00000012;
}

.left-sec img {
  object-fit: contain;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 450px;
}

.slick-dots li button:before {
  font-size: 20px !important;
}
